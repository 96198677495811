<template>

    <div class="home container">
      <card type="prueba" class="m-5 mt-9">
        <div class="m-5">
        <div class="row">
          <div class="col-12 col-md-4">
            <img src="../assets/image/dashboard-stub.svg" alt="Gráfica del dashboard"/>
          </div>
          <div class="col-12 col-md-8" style="text-align:left;">
            <h4>{{$t('home.title')}}</h4>
            <p>{{$t('home.desc')}}</p>
            <!--<router-link to="/about" class="nav-link btn btn-bextsocial">About</router-link>-->
            <router-link :to="$i18nRoute({name: 'Create Search'})" class="nav-link btn btn-bextsocial" >{{$t('home.button')}}</router-link>
          </div>
        </div>
      </div>
    </card>
     <modalpeq group="foo1" position="bottom left" />
    </div>
   
</template>

<script>
// @ is an alias to /src

import Card from "@/components/card/Card.component.vue";
// import {mapState, mapMutations } from "vuex";
import {mapState, mapActions } from "vuex";
//import HelloWorld from "@/components/HelloWorld.vue"

export default {
  name: "Home",
  components: {
     Card,
    //HelloWorld
  },
  data(){
    return {
      modalmen: "",
      modaltitle: ''
    }
  },
  computed: {
    ...mapState(["social"]),
    ...mapState('user', ['logged'])
  },
  beforeRouteLeave(to, from, next) {
    const prueba = this.social.filter(element => element.view == true);
    if (prueba.length < 1) {
      this.$hijonew({
        group: "foo1",
        type: "danger",
        title: "Advertencia",
        text: "Primero debe seleccionar que empresa desea monitorear",
        duration: 1000,
        speed: 1000,
      });
      next();
    } else {
      next();
    }
  },
  created(){
    this.modalmen = this.$tc('home.modalmenj')
    this.modaltitle = this.$tc('home.modaltitle')
    if(!this.logged){
      this.$fire({
          title: this.modaltitle,
          type: "info",
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: 'OK',
          html: "<p>"+this.modalmen+"</p>"
        }).then(async r => {
          this.uploadLogged(true)    
        })
    }
  },
  methods:{
    ...mapActions('user', ['uploadLogged'])
  }, 
  mounted() {
    const prueba = this.social.filter(element => element.view == true);
    if(prueba.length < 1){
      document.querySelector(".change-account").click()
    }
  },
};
</script>
<style lang="scss" scope>
  .home{
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 50px;
    padding-right: 50px;
    .card-prueba{
      padding: 40px 99px;
      a.nav-link.btn.btn-bextsocial{
        padding: 8px 20px;
        font-size: 18px;
      }
    }
    img{
      width: 100%;
      height: auto;
    }
  }
  .btn-bextsocial{
    width: 181px;
  }
</style>